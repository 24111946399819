import { useToast } from "vue-toastification";

function errorMessage(_title, _message, _timer) {
  let timer = _timer ? _timer : 4000;
  let toast = useToast();

  toast.error(_message, {
    timeout: timer
  });

  // Vue.$toast(_message, {
  //   timeout: timer,
  //   type: 'error'
  // });
  // $.notify(
  //   {
  //     title: _title,
  //     message: _message,
  //   },
  //   {
  //     type: "danger",
  //     allow_dismiss: false,
  //     newest_on_top: true,
  //     mouse_over: true,
  //     showProgressbar: false,
  //     spacing: 10,
  //     timer: timer,
  //     placement: {
  //       from: "top",
  //       align: "right",
  //     },
  //     offset: {
  //       x: 30,
  //       y: 30,
  //     },
  //     delay: 1000,
  //     z_index: 10000,
  //     animate: {
  //       enter: "animated bounceIn",
  //     },
  //   }
  // );
}

function doneMessage(_title, _message, _timer) {
  let timer = _timer ? _timer : 4000;
  let toast = useToast();
  
  toast.success(_message, {
    timeout: timer
  });
  // Vue.$toast(_message, {
  //   timeout: timer,
  //   type: 'success'
  // });
  // $.notify(
  //   {
  //     title: _title,
  //     message: _message,
  //   },
  //   {
  //     type: "success",
  //     allow_dismiss: false,
  //     newest_on_top: true,
  //     mouse_over: true,
  //     showProgressbar: false,
  //     spacing: 10,
  //     timer: timer,
  //     placement: {
  //       from: "top",
  //       align: "right",
  //     },
  //     offset: {
  //       x: 30,
  //       y: 30,
  //     },
  //     delay: 1000,
  //     z_index: 10000,
  //     animate: {
  //       enter: "animated bounceIn",
  //     },
  //   }
  // );
}

export { errorMessage, doneMessage };
