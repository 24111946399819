import { showLoading, hideLoading } from "./utils";
import { doneMessage, errorMessage } from "./notifications";


function handlerStatusResponse(response, showAlert) {
  if (showAlert) {
    if (response.status >= 200 && response.status <= 299) {
      doneMessage("", "Procesado correctamente");
    } else {
      throw new Error("Ha ocurrido un error inesperado");
    }
  }
}


// Ejemplo implementando el metodo POST:
async function postData(url = "", data = {}, showAlert = true) {
  try {
    showLoading();

    const response = await fetch(url, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    });

    hideLoading();
    handlerStatusResponse(response, showAlert);

    return response.json();
  } catch (error) {
    hideLoading();
    errorMessage("", "Ha ocurrido un error");
    return null;
  }
}

async function putData(url = "", id = "", data = {}, showAlert = true) {
  try {
    showLoading();
    const response = await fetch(`${url}/${id}`, {
      method: "PUT", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    });
    hideLoading();
    handlerStatusResponse(response, showAlert);

    return response.json();
  } catch (error) {
    hideLoading();
    errorMessage("", "Ha ocurrido un error");
    return null;
  }

}

async function getData(url = "") {
  try {
    showLoading();
    const response = await fetch(url, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
    });

    hideLoading();
    handlerStatusResponse(response, false);

    return response.json();
  } catch (error) {
    hideLoading();
    errorMessage("", "Ha ocurrido un error");
    return null;
  }

}

async function deleteData(url = "", id = "") {
  try {
    showLoading();
    const response = await fetch(`${url}/${id}`, {
      method: "DELETE", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
    });

    hideLoading();
    handlerStatusResponse(response);

    return response.json();
  } catch (error) {
    hideLoading();
    errorMessage("", "Ha ocurrido un error");
    return null;
  }

}

export { getData, postData, putData, deleteData };