import { createApp } from "vue";
import { deleteData, getData, postData, putData } from '../../../js/helpers/fetchhandler.js';
import buttoncomponent from "../../../components/Button.vue";

createApp({
    data() {
        return {
            urlRequest: '/api/landing/home',
            descripcion: null,
            pantalla: {},
            botones: [],
            codigo_qr: {}
        }
    },
    components: {
        buttoncomponent
    },
    created() {
    },
    mounted() {
        this.descripcion = (document.getElementById("descripcion").value == '' ? 'principal' : document.getElementById("descripcion").value);
        this.get();
    },
    updated() { },
    computed: {
        pantallastyle() {
            return {
                '--urlFondo': 'url('+this.pantalla.imagen_path+')'
            }
        }
    },
    methods: {
        get() {
            try {
                let _this = this;
                getData(`${_this.urlRequest}/datoslanding/${_this.descripcion}`).then((result) => {
                    _this.pantalla = result.pantalla[0];
                    _this.botones = result.botones;
                    _this.codigo_qr = result.codigo_qr;
                });
            } catch (error) {

            }
        }
    },

}).mount(`#content`);