<template>
    <template v-if="modo == 'edicion'">
        <div class="col-md-4 mb-3">
            <div class="light-card balance-card widget-hover">
                <div class="wrapper">
                    <a class="button" href="#!" :style="btnstyle" v-on:click="openFile()">
                        <span v-html="button.icono"></span>
                        {{ button.texto }}
                    </a>
                </div>
                <div class="ms-auto text-end">
                    <div class="dropdown icon-dropdown">
                        <button class="btn dropdown-toggle" id="incomedropdown" type="button" data-bs-toggle="dropdown"
                            aria-expanded="false"><i class="icon-more-alt"></i>
                        </button>
                        <div class="dropdown-menu dropdown-menu-end" aria-labelledby="incomedropdown">
                            <button class="dropdown-item" v-on:click="edit">Editar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </template>
    <template v-else>
        <a class="button" href="#!" :style="btnstyle" v-on:click="openFile()">
            <span v-html="button.icono"></span>
            {{ button.texto }}
        </a>
    </template>
    <!-- Filter: https://css-tricks.com/gooey-effect/ -->
    <svg style="visibility: hidden; position: absolute;" width="0" height="0" xmlns="http://www.w3.org/2000/svg"
        version="1.1">
        <defs>
            <filter id="goo">
                <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
                <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="goo" />
                <feComposite in="SourceGraphic" in2="goo" operator="atop" />
            </filter>
        </defs>
    </svg>
</template>

<script>

export default {
    props: {
        button: {
            type: Object,
            default() {
                return {};
            },
        },
        key: {
            type: Number,
            default: 0
        },
        modo: {
            type: String,
            default: 'vista'
        },
    },
    emits: ['openform'],
    components: {},
    mounted() { },
    watch: {},
    created() { },
    data() {
        return {};
    },
    computed: {
        btnstyle() {
            return {
                '--bgColor': this.button.activo == '1' ? this.button.color : '#ABABAB',
                '--txColor': this.button.color_texto
            }
        }
    },
    methods: {
        openFile() {
            try {
                if (this.button.tipo == 'archivo') {
                    window.open(app.config.urls.baseurl + this.button.url_path, '_blank');
                } else {
                    window.open(this.button.url_path, '_blank');
                }
            } catch (error) {

            }
        },
        edit() {
            let _this = this;
            this.$emit("openform", 'open', _this.button);
        }
    }
};
</script>

<style>
.wrapper {
    padding: 1.5rem 0;
    filter: url("#goo");
}

.button {
    display: inline-block;
    text-align: center;
    color: #ffffff;
    font-weight: bold;
    padding: 1.18em 1.32em 1.03em;
    line-height: 1;
    border-radius: 1em;
    position: relative;
    min-width: 8.23em;
    text-decoration: none;
    font-family: Montserrat, Roboto, Helvetica, Arial, sans-serif;
    font-size: 1.25rem;
}

.button {
    background: var(--bgColor);
    color: var(--txColor);
}

.button:before,
.button:after {
    width: 4.4em;
    height: 2.95em;
    position: absolute;
    content: "";
    display: inline-block;
    border-radius: 50%;
    transition: transform 0.5s ease;
    transform: scale(0);
    z-index: -1;
}

.button:before,
.button:after {
    background: var(--bgColor);
}

.button:before {
    top: -25%;
    left: 20%;
}

.button:after {
    bottom: -25%;
    right: 20%;
}

.button:hover:before,
.button:hover:after {
    transform: none;
}
</style>